import { FC, useContext, useEffect, useMemo } from 'react';
import { Loader } from 'react-ui-kit-exante';

import { useLazyGetApplicationTagsQuery } from '~/api';

import {
  getAllDynamicSectionNames,
  getSections,
  getTagsForSection,
} from '../../../ApplicationEntry.helpers';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { ApplicationFormProvider } from '../../../contexts/FormContext';
import { TabsContext } from '../../../contexts/TabsContext';
import { StyledTabHeader } from '../../../tabs/TabEntry/TabEntry.styled';
import { Section } from '../Section';

import { StyledColumn, StyledColumns } from './TabStructure.styled';
import { TTabStructureProps } from './TabStructure.types';

export const TabStructure: FC<TTabStructureProps> = ({ headerComponent }) => {
  const [getApplicationTagsQuery, state] = useLazyGetApplicationTagsQuery();

  const { currentTab } = useContext(TabsContext);
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const tab = currentTab?.tab;

  const positionSections = useMemo(
    () => tab?.children?.filter((item) => item.position),
    [tab],
  );

  const columns = [positionSections?.[0], positionSections?.[1]].filter(
    Boolean,
  );

  const allSections = useMemo(
    () =>
      columns.length > 0
        ? columns.flatMap((i) =>
            getSections(i?.children).map((section) => {
              return section;
            }),
          )
        : getSections(tab?.children).map((section) => {
            return section;
          }),
    [columns, tab],
  );

  const getTags = (names?: string) => {
    const applicationId = application?.application?.id;
    if (applicationId) {
      getApplicationTagsQuery({
        objectId: applicationId,
        sectionName: names || '',
        legalEntity: activeLegalEntity,
      });
    }
  };

  useEffect(() => {
    if (allSections.length && tab && state.isUninitialized) {
      const fields = getAllDynamicSectionNames(allSections);
      getTags(fields.join(','));
    }
  }, [allSections, tab, state, activeLegalEntity, application]);

  const allEditableTagsForSection = allSections?.flatMap((section) => {
    return (
      getTagsForSection(state?.data, section?.children?.[0])?.filter((i) => {
        if (i.readonly) {
          return false;
        }
        if (i.tagvalue_set[0]?.readonly) {
          return false;
        }
        return i.tagvalue_set?.[0]?.is_can_edit !== false;
      }) || []
    );
  });

  if (!tab) {
    return null;
  }

  if (state.isLoading || state.isFetching) {
    return <Loader isCentered size="l" />;
  }

  return (
    <ApplicationFormProvider
      editableTags={allEditableTagsForSection}
      allTags={state?.data}
    >
      {headerComponent && (
        <StyledTabHeader className="TabHeader">
          {headerComponent}
        </StyledTabHeader>
      )}
      {columns.length > 0 && (
        <StyledColumns className="Columns">
          {columns.map((i, idx) => {
            return (
              <StyledColumn className="Column" key={idx}>
                {getSections(i?.children).map((section) => {
                  return (
                    <Section
                      key={section.name}
                      section={section}
                      tags={getTagsForSection(
                        state?.data,
                        section?.children?.[0],
                      )}
                    />
                  );
                })}
              </StyledColumn>
            );
          })}
        </StyledColumns>
      )}

      {!columns.length &&
        getSections(tab?.children).map((section) => {
          return (
            <Section
              key={section.name}
              section={section}
              tags={getTagsForSection(state?.data, section?.children?.[0])}
            />
          );
        })}
    </ApplicationFormProvider>
  );
};
