import { FC, useContext, useState } from 'react';
import { IconButton, Select, Input } from 'react-ui-kit-exante';

import {
  useEditDocFileMutation,
  useEditDocGroupMutation,
  useLazyGetDocsQuery,
  useRenameFilenameMutation,
} from '~/api';
import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { OnboardingSettingsContext } from '~/pages/ApplicationEntry/components/OnboardingSettings/context';
import { getTableId } from '~/utils/table';

import { StyledEditActionCellForm } from './EditActionCell.styled';
import { TEditActionCell } from './EditActionCell.types';

export const EditActionCell: FC<TEditActionCell> = ({
  fileId,
  history,
  documentOptions,
  type,
  filename,
}) => {
  const { application } = useContext(OnboardingSettingsContext);

  const tableId = getTableId('REQUESTS_TABLE_NAME');

  const [editDocument, stateEditDocument] = useEditDocFileMutation();
  const [editDocGroup, stateEditDocGroup] = useEditDocGroupMutation();
  const [renameFilename, stateRenameFilename] = useRenameFilenameMutation();
  const [fetchDocs, stateDocs] = useLazyGetDocsQuery();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [btnAnchorEl, setBtnAnchorEl] = useState<null | HTMLElement>(null);
  const [moderatorComment, setModeratorComment] = useState(
    history?.moderator_comment || '',
  );
  const [userComment, setUserComment] = useState(history?.user_comment || '');
  const [name, setName] = useState(filename || '');
  const [documentGroup, setDocumentGroup] = useState(type);

  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isCommentDirty, setIsCommentDirty] = useState(false);
  const [isDocGroupDirty, setIsDocGroupDirty] = useState(false);

  const isDisabled =
    stateEditDocument.isLoading ||
    stateEditDocGroup.isLoading ||
    stateDocs.isLoading ||
    stateRenameFilename.isLoading;

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNameDirty(true);
    setName(e.target.value);
  };

  const handleOnChangeGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentGroup(e.target.value);
    setIsDocGroupDirty(true);
  };

  const handleOnChangeModeratorComment = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setModeratorComment(e.target.value);
    setIsCommentDirty(true);
  };

  const handleOnChangeUserComment = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserComment(e.target.value);
    setIsCommentDirty(true);
  };

  const renderBlockConfirm = (
    <StyledEditActionCellForm className="EditActionCellForm">
      <Input fullWidth value={name} onChange={handleOnChangeName} />
      <Select
        sx={{ width: 300 }}
        options={documentOptions}
        onChange={handleOnChangeGroup}
        value={documentGroup}
        placeholder="Document group"
        label="Document group"
      />
      <Input
        type="text"
        value={moderatorComment}
        onChange={handleOnChangeModeratorComment}
        placeholder="Change moderator comment"
        label="Change moderator comment"
        fullWidth
      />
      <Input
        type="text"
        value={userComment}
        onChange={handleOnChangeUserComment}
        placeholder="Change user comment"
        label="Change user comment"
        fullWidth
      />
    </StyledEditActionCellForm>
  );

  const openConfirm = (event: React.MouseEvent<HTMLElement>) => {
    setIsOpenConfirm(!isOpenConfirm);
    setBtnAnchorEl(event.currentTarget);
  };

  const closeConfirm = () => {
    setIsOpenConfirm(!isOpenConfirm);
    setBtnAnchorEl(null);
    setIsNameDirty(false);
    setIsCommentDirty(false);
    setIsDocGroupDirty(false);
  };

  const handleEdit = async () => {
    if (isCommentDirty) {
      await editDocument({
        fileId,
        data: {
          moderator_comment: moderatorComment,
          user_comment: userComment,
          greed: history?.status,
        },
      });
    }

    if (isDocGroupDirty) {
      await editDocGroup({ fileId, type: documentGroup });
    }

    if (isNameDirty) {
      await renameFilename({ fileId, filename: name });
    }

    if (isDocGroupDirty) {
      [type, documentGroup].forEach((item) =>
        fetchDocs({
          type: item,
          applicationId: application?.application?.id,
        }),
      );

      return;
    }

    fetchDocs({
      type: documentGroup,
      applicationId: application?.application?.id,
    });

    closeConfirm();
  };

  return (
    <ActionWithConfirmationWithSelect
      key="onRejectBtn"
      open={isOpenConfirm}
      anchorEl={btnAnchorEl}
      onClose={closeConfirm}
      title="Editing document"
      content={renderBlockConfirm}
      confirmText="Save"
      handleConfirm={handleEdit}
      handleClose={closeConfirm}
      disabledConfirm={isDisabled}
    >
      <IconButton
        iconSize={24}
        iconColor="secondary"
        iconName="EditIcon"
        data-test-id={`${tableId}__button--edit-record`}
        title="Edit file"
        onClick={openConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );
};
