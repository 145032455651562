import { FC } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { TabStructure } from '../../components/EnrtyScreenStructure/TabStructure/TabStructure';

export const QualifiedLeadTab: FC = () => {
  return (
    <TabStructure
      headerComponent={
        <IconButton
          label="Save"
          type="submit"
          iconName="SaveIcon"
          iconColor="action"
          iconSize={24}
          disabled={false}
          className="SaveQualifiedLeadButton"
          data-test-id="save-qualified-lead--button"
        />
      }
    />
  );
};
