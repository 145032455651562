import { FC, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetComputedUsersQuery } from '~/api';
import { FakeTableLoader } from '~/components/FakeTableLoader';
import { useGetTableFilterOptions } from '~/hooks';
import { WithBookmarks } from '~/modules/bookmarks/components/WithBookmarks';
import { TBookmarkResponseProps } from '~/modules/bookmarks/hooks/useBookmark/types';
import { useDynamicData } from '~/pages/ComputedUsers/useDynamicData';
import { prepareSelectedColumns } from '~/utils/prepareSelectedColumns';
import {
  getAdditionalFilters,
  getTableColumnsFromLs,
  getDefaultPagination,
} from '~/utils/table';

import { TUser } from '../../api/nodeBackApi/users/users.types';
import { PATHS } from '../../router';

import {
  calculateCountOfPages,
  DEFAULT_PAGINATION_RESPONSE,
  DISPLAYED_COLUMN_KEYS,
  DEFAULT_SORTING,
  EXCLUDED_PARAMS,
  PAGE_SIZES,
} from './ComputedUsers.constants';
import { TComputedUsersProps } from './ComputedUsers.types';
import {
  paramsTransformer,
  USERS_PARAMS_TRANSFORMER,
} from './ComputedUsers.utils';

export const ComputedUsers: FC<
  TComputedUsersProps & TBookmarkResponseProps
> = ({
  tableId,
  pageName,
  selectedBookmark,
  handleDeleteBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleSaveBookmark,
}) => {
  const navigate = useNavigate();

  const { filters, isLoadingMetadata, columns, defaultVisibleColumns } =
    useDynamicData();
  const [getComputedUsers] = useLazyGetComputedUsersQuery();

  const additionalOptions = useGetTableFilterOptions(filters);

  const selectedColumnsRef = useRef(
    prepareSelectedColumns(getTableColumnsFromLs(tableId)).split(','),
  );

  const fetchUsers = useCallback(
    async ({ params }: IOnFetchArguments) => {
      const response = await getComputedUsers(
        paramsTransformer({
          params: { ...params, columns: selectedColumnsRef.current },
          mapper: USERS_PARAMS_TRANSFORMER,
        }),
      );

      if (response.error || !response.data) {
        return { data: [], pagination: DEFAULT_PAGINATION_RESPONSE };
      }
      return response.data;
    },
    [getComputedUsers],
  );

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: fetchUsers,
      },
      pagination: { getDefaultPagination },
      saveViewParamsAfterLeave: true,
      excludedParams: EXCLUDED_PARAMS,
      tableId,
    }),
    [fetchUsers],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters: tableFilters,
    fetchData,
    params,
  } = useTableData(tableArgs);

  const onVisibleColumnsChange = (keys: string[]) => {
    selectedColumnsRef.current = keys;
    fetchData();
  };

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters,
        additionalOptions,
      }),
    [removeFilter, setFilter, filters, Object.keys(additionalOptions)],
  );
  const total = data?.pagination?.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters: tableFilters,
      manualFilters: true,
    }),
    [additionalFilters, tableFilters, resetFilters],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const computedColumns = useMemo(() => columns, [columns]);

  const bookmarkComponent = useMemo(() => {
    if (!selectedBookmark) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, tableFilters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, tableFilters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    tableFilters,
    filters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const handleRowClick = useCallback(
    ({ username }: TUser) => {
      if (username) {
        navigate(`${PATHS.USERS}/${username}/activity`, {
          // todo think about it (info link hardcode)
          state: {
            previousPath: window.location.href,
            requestParams: params,
          },
        });
      }
    },
    [navigate, params],
  );

  if (isLoadingMetadata || !computedColumns.length) {
    return <FakeTableLoader title={pageName} />;
  }

  return (
    <Table
      className="UsersTable"
      title={pageName}
      tableId={tableId}
      data={data?.data || []}
      columns={computedColumns}
      filtersRightPanelComponent={bookmarkComponent}
      displayedColumnKeys={
        defaultVisibleColumns?.length
          ? defaultVisibleColumns
          : DISPLAYED_COLUMN_KEYS
      }
      filteringProps={filteringProps}
      filtersExpanded
      hasFilters
      isFlexLayout
      onVisibleColumnsChange={onVisibleColumnsChange}
      isLoading={isLoading || isLoadingMetadata}
      onSort={setSorting}
      defaultSortBy={DEFAULT_SORTING}
      handleRowClick={handleRowClick}
      manualSortBy
      hasPagination
      serverPaginationProps={serverPaginationProps}
      showScrollbar
      saveViewParamsAfterLeave
      saveColumnOrder
      isHiddenColumnSelectControls
      showTableInfo
      isNotSaveVisibleColumns={!!selectedBookmark?.id}
      hasNegativeFilters
      pageSizes={PAGE_SIZES}
    />
  );
};

export const UsersContainer = () => {
  return (
    <WithBookmarks component={ComputedUsers} pageName="Users" tableId="users" />
  );
};
