import { FC } from 'react';

import { StaticSectionKey } from '../../../ApplicationEntry.constants';
import { SectionBody } from '../SectionBody';

import { mapSectionKeyToStaticComponent } from './Section.constants';
import { StyledSection, StyledTitle } from './Section.styled';
import { TSectionProps } from './Section.types';

export const Section: FC<TSectionProps> = ({ section, tags }) => {
  const sectionIsStatic = section.resourcetype === StaticSectionKey;
  const sectionName = section.name;
  return (
    <StyledSection className="Section">
      <StyledTitle className="StyledTitle">{section.title}</StyledTitle>
      {sectionIsStatic &&
        sectionName &&
        mapSectionKeyToStaticComponent[sectionName]}
      {tags && <SectionBody tags={tags} />}
    </StyledSection>
  );
};
