import { omit } from 'lodash';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  IOnFetchArguments,
  Table,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetNotificationsNodeBackQuery } from '~/api';
import { TNotification } from '~/api/nodeBackApi/notifications/notifications.types';
import { RefreshButton } from '~/components/RefreshButton';
import { TParams } from '~/router/router.types';
import { getDefaultPagination, getTableId } from '~/utils/table';

import { ApplicationContext } from '../../contexts/ApplicationContext';

import { prepareNotificationsParams } from './Notifications.helpers';
import {
  DISPLAYED_COLUMNS_KEYS,
  EMPTY_NOTIFICATIONS,
  UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE,
  channelOptions,
  getAdditionalFilters,
  getColumns,
} from './NotificationsTable.constants';

export const NotificationsTable: FC = () => {
  const { id } = useParams<TParams>();

  const { application } = useContext(ApplicationContext);

  const [fetchNotifications] = useLazyGetNotificationsNodeBackQuery();

  const tableId = getTableId('activity-notifications');

  const getNotifications = useCallback(async (params: IOnFetchArguments) => {
    if (!id || !application) {
      return EMPTY_NOTIFICATIONS;
    }

    const userId: number = application.user.authdb_id;

    const preparedParams = prepareNotificationsParams(params);

    const response = await fetchNotifications({
      ...omit(preparedParams, UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE),
      userId,
    });

    return response.data;
  }, []);

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: {
        onFetch: getNotifications,
      },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getNotifications, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    resetFilters,
    filters: filtersTable,
    setFilter,
    removeFilter,
    fetchData: refetch,
  } = useTableData(tableDataArgs);

  const total = Number(data?.pagination?.total) || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      removeAllFilters: resetFilters,
      filters: filtersTable,
      manualFilters: true,
    }),
    [resetFilters, filtersTable],
  );

  const columns = getColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
    channelOptions,
  });

  const displayedColumnKeys = useMemo(() => DISPLAYED_COLUMNS_KEYS, []);

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={refetch}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  return (
    <Table<TNotification>
      className="NotificationsTable"
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      isLoading={isLoading}
      filtersExpanded
      isFlexLayout
      hasFilters
      filteringProps={filteringProps}
      data={data?.data || []}
      tableId={tableId}
      hasPagination
      showTableInfo
      pageSize={20}
      serverPaginationProps={serverPaginationProps}
      saveViewParamsAfterLeave
      defaultSortBy={[]}
      additionalActions={additionalActions}
    />
  );
};
