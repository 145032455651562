import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, FileUpload } from 'react-ui-kit-exante';

import { useLazyGetDocTypesQuery, useUploadDocsMutation } from 'api';
import { EntrySection } from '~/components/EntryScreen';

import { OnboardingSettingsContext } from '../../context';
import { UploadedDocumentsTable } from '../UploadedDocumentsTable';

import {
  StyledDocumentsTableName,
  StyledDocumentsTableTitle,
  StyledDocumentsTitle,
} from './UploadedDocuments.styled';

export const UploadedDocuments = () => {
  const navigate = useNavigate();
  const { application } = useContext(OnboardingSettingsContext);

  const applicationId = application?.application?.id;

  const [fetchDocTypes, stateDocTypes] = useLazyGetDocTypesQuery();
  const [uploadDocs, stateUploadDocs] = useUploadDocsMutation();

  const dataDocTypes = stateDocTypes.data || [];

  useEffect(() => {
    if (applicationId) {
      fetchDocTypes({ applicationId });
    }
  }, [applicationId]);

  const handleOnClickDownloadAll = () =>
    navigate(`/application/${applicationId}/archive/`);

  const title = (
    <StyledDocumentsTitle className="DocumentsTitle">
      <div>Uploaded documents</div>
      <IconButton
        iconSize={24}
        iconName="DownloadIcon"
        iconColor="secondary"
        title="Download All"
        label="Download All"
        onClick={handleOnClickDownloadAll}
      />
    </StyledDocumentsTitle>
  );

  const handleFileAppend = async (payload: File[], type: string) => {
    payload.map(async (file) => uploadDocs({ applicationId, file, type }));
  };

  return (
    <EntrySection title={title}>
      {dataDocTypes.map((docType) => {
        return (
          <div key={docType?.key}>
            <StyledDocumentsTableTitle className="DocumentsTableTitle">
              <StyledDocumentsTableName className="DocumentsTableName">
                {docType?.title}
              </StyledDocumentsTableName>
              {stateUploadDocs.isLoading && (
                <FileUpload
                  disabled
                  buttonLabel="Upload New Document"
                  showDragAndDrop={false}
                />
              )}
              {!stateUploadDocs.isLoading && (
                <FileUpload
                  onUpload={(files) => handleFileAppend(files, docType?.key)}
                  showDragAndDrop={false}
                  buttonLabel="Upload New Document"
                />
              )}
            </StyledDocumentsTableTitle>
            <UploadedDocumentsTable
              type={docType?.key}
              dataDocTypes={dataDocTypes}
            />
          </div>
        );
      })}
    </EntrySection>
  );
};
