import { FC } from 'react';

import { ValueRow } from '~/components/ValueRow';
import { getFieldType } from '~/constants/common';
import { getFormField } from '~/utils/getFormField';

import { getTagValue } from '../../../ApplicationEntry.helpers';

import { StyledSectionBody, StyledFormField } from './SectionBody.styled';
import { TSectionBodyProps } from './SectionBody.types';

export const SectionBody: FC<TSectionBodyProps> = ({ tags = [] }) => {
  return (
    <StyledSectionBody className="SectionBody">
      {tags?.length > 0 &&
        tags.map((tag) => {
          const {
            readonly,
            tagvalue_set: tagValueSet,
            id,
            title,
            name,
            choices,
          } = tag;
          if (
            readonly ||
            tagValueSet?.[0]?.readonly ||
            tagValueSet?.[0]?.is_can_edit === false
          ) {
            return (
              <ValueRow
                key={id}
                label={title || name}
                value={getTagValue(tag)}
              />
            );
          }
          return (
            <div key={id}>
              <ValueRow
                key={id}
                label={title || name}
                valueNode={
                  <StyledFormField className="FormField">
                    {getFormField({
                      type: getFieldType(tag),
                      name,
                      options: choices?.split(',').map((i) => ({
                        value: i,
                        label: i,
                      })),
                    })}
                  </StyledFormField>
                }
              />
            </div>
          );
        })}
    </StyledSectionBody>
  );
};
