import { SyntheticEvent, useContext, useState } from 'react';
import { Autocomplete, IconButton, ISelectOption } from 'react-ui-kit-exante';

import { ApplicationFormContext } from '../../../../../contexts/FormContext';

import { StyledAddTagRow } from './AddNewTag.styled';

export const AddNewTag = () => {
  const { allTags } = useContext(ApplicationFormContext);
  const [tagValue, setTagValue] = useState<ISelectOption | null>(null);

  const ohChangeHandler = (event: SyntheticEvent, value: ISelectOption) => {
    setTagValue(value);
  };

  const otherTags =
    allTags?.find((tags) => tags.name.toLowerCase() === 'other')?.tags || [];

  const otherTagsOptions: ISelectOption[] = otherTags?.map((tag) => {
    return {
      value: tag.name,
      label: tag.title,
    };
  });

  const onAddTag = () => {
    setTagValue(null);
  };

  return (
    <StyledAddTagRow className="AddTagRow">
      <Autocomplete
        isMultiple={false}
        options={otherTagsOptions}
        fullWidth
        placeholder="Add new tag"
        value={tagValue}
        onChange={ohChangeHandler}
        className="AddNewTagAutocomplete"
        data-test-id="add-new-tag__autocomplete"
      />
      <IconButton
        iconName="AddIcon"
        label="Add tag"
        type="submit"
        iconColor="action"
        iconSize={24}
        disabled={!tagValue}
        onClick={onAddTag}
        className="AddNewTagButton"
        data-test-id="add-new-tag__button"
      />
    </StyledAddTagRow>
  );
};
