import { useContext, useEffect } from 'react';
import { Table } from 'react-ui-kit-exante';

import { useLazyGetDocsQuery } from '~/api';
import { TDocTypes } from '~/api/docs/docs.types';

import { OnboardingSettingsContext } from '../../context';

import { getColumns } from './helpers';

export const UploadedDocumentsTable = ({
  type,
  dataDocTypes,
}: {
  type: string;
  dataDocTypes: TDocTypes[];
}) => {
  const { application } = useContext(OnboardingSettingsContext);

  const [fetchDocs, stateDoc] = useLazyGetDocsQuery();

  const tableData = stateDoc?.data?.results || [];

  useEffect(() => {
    fetchDocs({
      type,
      applicationId: application?.application?.id,
    });
  }, []);

  const columns = getColumns(dataDocTypes, type);

  if (tableData?.length === 0) {
    return null;
  }

  return (
    <Table
      manualSortBy
      isFlexLayout
      disableSortBy
      tableId={type}
      data={tableData}
      columns={columns}
      isHiddenColumnSelect
      showTableInfo={false}
      isLoading={stateDoc.isLoading}
    />
  );
};
