export const mapTabsUrls = {
  // actions: 'info',
  // 'app-onboarding': 'onboarding',
  // 'qualified-lead': 'qualified-lead',

  // onboarding_settings_subtab: 'onboarding_settings_subtab',
  // 'affiliated-subtab': 'affiliated-subtab',
  // 'monitoring-subtab': 'monitoring-subtab',
  // 'risk_scoring-subtab': 'risk_scoring-subtab',

  activity: 'activity',
  'trades-subtab': 'trades-subtab',
  'transactions-subtab': 'transactions-subtab',
  'notifications-activity-subtab': 'notifications-activity-subtab',
  'audit-logs-subtab': 'audit-logs-subtab',
};
