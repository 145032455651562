import { Autocomplete, Input, styled } from 'react-ui-kit-exante';

export const StyledFormRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 24px;
  width: 100%;
  padding: 0 24px 0;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 240px;
`;

export const StyledButtonGroupContainer = styled('div')`
  padding: 0 24px;
`;

export const StyledTabActionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const StyledTabActionsButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const StyledCommentInput = styled(Input)`
  .MuiInputBase-multiline {
    textarea {
      padding: 0;
    }
  }
`;
