import {
  FormCheckboxContainer,
  FormDatePickerContainer,
  FormInputContainer,
  FormMultiSelectContainer,
  FormSelectContainer,
  FormFileUploadContainer,
} from '~/components/Form';
import { DEFAULT_DATE_FORMAT, FieldTypes } from '~/constants/common';

import { TField } from './getFormField.types';

export const getFormField = (field: TField) => {
  const {
    name,
    label,
    disabled,
    options,
    type,
    isMultiple,
    isLoading,
    clearable,
    message,
    size,
    onDeleteFile,
    onDownloadFile,
    width,
  } = field;

  if (type === FieldTypes.Select) {
    return (
      <FormSelectContainer
        key={name}
        name={name}
        label={label}
        disabled={disabled || isLoading}
        options={options || []}
        size={size || 'small'}
        fullWidth
        message={message}
        width={width}
      />
    );
  }

  if (type === FieldTypes.Autocomplete) {
    return (
      <FormMultiSelectContainer
        key={name}
        name={name}
        label={label}
        disabled={disabled || isLoading}
        options={options || []}
        size={size || 'small'}
        isMultiple={isMultiple}
        disableClearable={!clearable}
        fullWidth
      />
    );
  }

  if (type === FieldTypes.Checkbox) {
    return (
      <FormCheckboxContainer
        key={name}
        name={name}
        label={label}
        disabled={disabled}
      />
    );
  }

  if (type === FieldTypes.DateField) {
    return (
      <FormDatePickerContainer
        key={name}
        fullWidth
        name={name}
        label={label}
        disabled={disabled}
        size={size || 'small'}
        dateFormat={DEFAULT_DATE_FORMAT}
      />
    );
  }

  if (type === FieldTypes.DateTimeField) {
    return (
      <FormDatePickerContainer
        key={name}
        fullWidth
        name={name}
        label={label}
        disabled={disabled}
        size="small"
        showTimeInput
        dateFormat={DEFAULT_DATE_FORMAT}
      />
    );
  }

  if (type === FieldTypes.NumberField) {
    return (
      <FormInputContainer
        key={name}
        type="number"
        name={name}
        label={label}
        disabled={disabled}
        size={size || 'small'}
        fullWidth
      />
    );
  }

  if (type === FieldTypes.Textarea) {
    return (
      <FormInputContainer
        key={name}
        name={name}
        label={label}
        disabled={disabled}
        size={size || 'small'}
        fullWidth
        multiline
      />
    );
  }

  if (type === FieldTypes.File) {
    return (
      <FormFileUploadContainer
        removable
        name={name}
        showFileSize={false}
        loading={isLoading}
        onDeleteFile={onDeleteFile}
        onDownloadFile={onDownloadFile}
      />
    );
  }

  return (
    <FormInputContainer
      key={name}
      fullWidth
      name={name}
      label={label}
      disabled={disabled}
      size={size || 'small'}
    />
  );
};
