import { styled } from 'react-ui-kit-exante';

export const StyledDocumentsTitle = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledDocumentsTableTitle = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledDocumentsTableName = styled('h4')`
  font-size: 18px;
  font-weight: 400;
`;
